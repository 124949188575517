<template>
    <section class="step-section">
        <header class="step-header">
            <h2 class="step-header__title">
                {{ steps[currentStep] }}
            </h2>
            <IconLoading v-if="!existingTrip" style="margin-left: 20px;" />
        </header>

        <article class="document document--grey-header step-section__document" v-if="existingTrip">
            <header class="document__header">
                <h3 class="document__header-title">
                    {{ __('main.' + tripType + '_trip') }} #{{ existingTrip.id_formatted }}
                </h3>
            </header>
            <div class="document__body">
                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Trip information") }}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                            <p>{{ __('json.Reservation ID') }}</p>
                            <p>{{ existingTrip.id_formatted }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.The Expected Date of Arrival to Iraq") }}</p>
                            <p>{{ dateOfArrivalFormatted }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.MRN/TIR/CMR/CIN") }}</p>
                            <p>{{ existingTrip.mrn }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Brand") }}</p>
                            <p>{{ existingTrip.brand.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Carrier") }}</p>
                            <p>{{ existingTrip.car_owner_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Model") }}</p>
                            <p>{{ existingTrip.model.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Licence country") }}</p>
                            <p>{{ existingTrip.license_country.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Plate number") }}</p>
                            <p>{{ existingTrip.plate_number }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.VIN") }}</p>
                            <p>{{ existingTrip.vin }}</p>
                        </div>
                    </div>
                </div>

                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{__("json.Driver information")}}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                            <p>{{ __("json.Driver Name") }}</p>
                            <p>{{ existingTrip.driver_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Mobile Number") }}</p>
                            <p dir="ltr">{{ existingTrip.driver_phone }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Passport No.") }}</p>
                            <p>{{ existingTrip.passport_id }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Nationality") }}</p>
                            <p>{{ existingTrip.nationality.name }}</p>
                        </div>
                    </div>
                </div>

                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Consignee") }}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                            <p>{{ __("json.Consignee") }}</p>
                            <p>{{ existingTrip.broker_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Address") }}</p>
                            <p>{{ existingTrip.consignee_address }}</p>
                        </div>
                        <!-- <div class="document__row">
                            <p>{{ __("json.Passport No.") }}</p>
                            <p>{{ existingTrip.broker_passport_id }}</p>
                        </div> -->
                        <div class="document__row">
                            <p>{{ __("json.Phone Number") }}</p>
                            <p dir="ltr">{{ existingTrip.broker_phone }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Broker Name") }}</p>
                            <p>{{ existingTrip.real_broker_name }}</p>
                        </div>
                    </div>
                </div>

                <div class="document__block" >
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Border registration information") }}
                    </h4>
                    <div class="document__grid" v-if="tripType === 'transit'">
                        <div class="document__row">
                            <p>{{ __("json.Country of Arrival") }}</p>
                            <p>{{ existingTrip.from_country.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Port of Entry") }}</p>
                            <p>{{ existingTrip.from_border.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Country of Departure") }}</p>
                            <p>{{ existingTrip.to_country.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Port of Exit") }}</p>
                            <p>{{ existingTrip.to_border.name }}</p>
                        </div>
                    </div>
                    <div class="document__grid" v-else-if="tripType === 'external'">
                        <div class="document__row">
                            <p>{{ __("json.Country of Arrival") }}</p>
                            <p>{{ existingTrip.from_country.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Port of Entry") }}</p>
                            <p>{{ existingTrip.from_border.name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Address") }}</p>
                            <p>{{ existingTrip.destination_address }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Location latitude and longitude") }}</p>
                            <p>{{ existingTrip.destination_latitude }}, {{ existingTrip.destination_longitude }}</p>
                        </div>
                    </div>
                </div>

                <div class="document__block">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Cargo supplier") }}
                    </h4>
                    <div class="document__grid">
                        <div class="document__row">
                            <p>{{ __("json.Sender") }}</p>
                            <p>{{ existingTrip.cargo_company_name }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Address") }}</p>
                            <p dir="ltr">{{ existingTrip.sender_address }}</p>
                        </div>
                        <div class="document__row">
                            <p>{{ __("json.Phone Number") }}</p>
                            <p dir="ltr">{{ existingTrip.cargo_owner_poc_phone }}</p>
                        </div>
                    </div>
                </div>

                <div class="document__block" v-if="existingTrip.cargo_infos.length">
                    <h4 class="document__title document__title--blue">
                        {{ __("json.Cargo Information") }}
                    </h4>
                    <template v-for="(cargoInfo, index) in existingTrip.cargoInfos">
                        <h5 class="document__sub-title">{{ __("json.Cargo") }} {{ index + 1}}</h5>
                        <div class="document__grid">
                            <div class="document__row">
                                <p>{{ __("json.MRN/TIR/CMR/CIN") }}</p>
                                <p>{{ cargoInfo.mrn }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Sector") }}</p>
                                <p>{{ cargoInfo.sector?.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Value") }}</p>
                                <p>{{ cargoInfo.values_usd }} {{ cargoInfo.currency?.code }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Country of Destination") }}</p>
                                <p>{{ cargoInfo.destination_country?.name }}</p>
                            </div>
                            <!-- <div class="document__row">
                                <p>{{ __("json.Cargo values in dollar") }}</p>
                                <p>{{ cargoInfo.values_usd }}</p>
                            </div> -->
                            <div class="document__row">
                                <p>{{ __("json.Origin") }}</p>
                                <p>{{ cargoInfo.origin_country?.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Measure type") }}</p>
                                <p>{{ cargoInfo.measure?.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.BGW type") }}</p>
                                <p>{{ cargoInfo.bgw_type?.name }}</p>
                            </div>
                            <div class="document__row" style="width: 100%">
                                <p>{{ __("json.H_S") }}</p>
                                <p>
                                    <div v-for="ln in cargoInfo.hsn_array" :key="ln.id">
                                        {{ ln.id }} {{ ln.name }} ( {{ ln.text }} )
                                    </div>
                                </p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.BGW name") }}</p>
                                <p>{{ cargoInfo.bgw_name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Cargo name") }}</p>
                                <p>{{ cargoInfo.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Cargo Weight") }}</p>
                                <p>{{ cargoInfo.weight }} {{ cargoInfo.measure.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Pallet") }}</p>
                                <p>{{ cargoInfo.total_weight }} {{ cargoInfo.totalWeightMeasure?.name }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Declaration date") }}</p>
                                <p>{{ cargoInfo.clearance_date ? toLocalTime(cargoInfo.clearance_date, 'YYYY-MM-DD') : '' }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Quantity") }}</p>
                                <p>{{ cargoInfo.quantity }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Declaration No") }}</p>
                                <p>{{ cargoInfo.clearance_id }}</p>
                            </div>
                            <div class="document__row">
                                <p>{{ __("json.Container No") }}</p>
                                <p>{{ cargoInfo.container_no }}</p>
                            </div>
                        </div>
                    </template>
                </div>

                <template v-for="(files, type) in typedFiles" :key="type">
                    <div class="document__block" v-if="files.length">
                        <h4 class="document__title document__title--blue">
                            {{ getTypedFilesTitle(type) }}
                        </h4>
                        <div class="file" v-for="file in files" :key="file.id">
                            <div class="file__pic">
                                <svg class="file__icon" width="18" height="24">
                                    <use xlink:href="/assets/images/new/sprite.svg#file-image"></use>
                                </svg>
                            </div>
                            <div class="file__body">
                                <div class="file__descr">
                                    <h4 class="file__title">
                                        {{ file.original_name }}
                                    </h4>
                                    <p class="file__size" v-if="false">Size: 240 KB</p>
                                </div>
                                <time class="file__date">
                                    {{ toLocalTime(file.created_at) }}
                                </time>
                            </div>
                            <div class="file__actions">
                                <a :href="file.temp_url" target="_blank" class="button-action file__button file__button--hidden-md" aria-label="View file">
                                    <svg class="button-action__icon" width="22" height="16">
                                        <use xlink:href="/assets/images/new/sprite.svg#view"></use>
                                    </svg>
                                </a>
                                <div v-if="false">
                                    <button class="button-action file__button file__button--hidden-md" type="button" aria-label="Download file">
                                        <svg class="button-action__icon" width="22" height="16">
                                            <use xlink:href="/assets/images/new/sprite.svg#download"></use>
                                        </svg>
                                    </button>
                                    <button class="button-action file__button" type="button" aria-label="Delete file">
                                        <svg class="button-action__icon" width="18" height="19">
                                            <use xlink:href="/assets/images/new/sprite.svg#trash"></use>
                                        </svg>
                                    </button>
                                    <button class="button-action file__button" type="button" aria-label="Close file">
                                        <svg class="button-action__icon" width="22" height="22">
                                            <use xlink:href="/assets/images/new/sprite.svg#close"></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </article>

        <VeeForm v-slot="{ handleSubmit, errors }" :validation-schema="schema" as="div" v-if="existingTrip">
            <form @submit="handleSubmit($event, onSubmit)">
                <label class="label label--bold" for="yourComment">
                    {{ __("json.Comment") }}
                    <span class="grey-color">{{ __("json.(Please add your comment to the reservation)") }}</span>
                </label>
                <textarea v-model="form.comment" class="textarea" id="yourComment" placeholder="Comments" rows="8"></textarea>

                <div class="step-form__buttons">
                    <button class="button button--outline step-form__button" type="button" @click="handlePrev">
                        {{ __("json.Previous step") }}
                    </button>
                    <button :disabled="isSubmitting" class="button button--blue step-form__button" type="submit">
                        <IconLoading v-if="isSubmitting" />
                        <span v-else>{{ __("json.Confirm") }}</span>
                    </button>
                </div>
            </form>
        </VeeForm>
    </section>
</template>

<script>
import {ErrorMessage, Field, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';
import IconLoading from "../partials/IconLoading.vue";
import {useGetExistingTrip} from "../utils/useGetExistingTrip";
import {useLocalTime} from "../utils/useLocalTime";

const schema = yup.object({});

export default {
    name: "Step7",
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        steps: {
            type: Object,
            required: true
        },
        tripType: {
            type: String,
            required: true
        },
        action: {
            type: String,
            required: true
        },
        updateTripId: {
            type: Number,
            required: false
        },
    },
    components: {
        VeeForm,
        Field,
        ErrorMessage,
        IconLoading,
    },
    data() {
        return {
            currentStep: this.modelValue,
            form: {
                comment: null,
            },
            schema,
            isSubmitting: false,
            existingFiles: [],
        }
    },
    setup() {
        const { getExistingTrip, existingTrip } = useGetExistingTrip();
        const { toLocalTime } = useLocalTime();
        return {
            getExistingTrip, existingTrip,
            toLocalTime
        }
    },
    mounted() {
        this.getExistingTrip(this.tripType, this.updateTripId).then((data) => {
            if (Object.keys(data).length !== 0) {
                this.updateFormData()
            }
        })
    },
    computed: {
        formWithTripType() {
            if (!('tripType' in this.form)) {
                Object.assign(this.form, { 'tripType': this.tripType })
            }
            return this.form;
        },
        dateOfArrivalFormatted() {
            return this.existingTrip
                && this.existingTrip.date_of_arrival
                && this.toLocalTime(this.existingTrip.date_of_arrival, 'YYYY-MM-DD')
        },
        typedFiles() {
            let typedFiles = {};
            this.existingFiles.forEach(file => {
                if (Array.isArray(typedFiles[file.type])) {
                    typedFiles[file.type].push(file);
                } else {
                    typedFiles[file.type] = [file];
                }
            });

            return typedFiles;
        },
    },
    emits: [
        'update:modelValue',
        'openSuccessModal',
    ],
    methods: {
        onSubmit() {
            this.isSubmitting = true;

            let formActionUrl = "/reservation/create";
            if (this.action === "update") {
                formActionUrl = "/reservation/update/" + this.existingTrip.id;
            }

            axios
                .post(formActionUrl, this.formWithTripType)
                .then(({data}) => {
                    this.$emit('openSuccessModal');
                    setTimeout(function () {
                        window.location.href = data.returnUrl;
                        this.isSubmitting = false;
                    }, 3000);
                })
                .catch(({response}) => {
                    console.log(response);
                    let errorString = response.data.message + `\n`;
                    for (const [key, value] of Object.entries(
                        response.data.errors
                    )) {
                        errorString += `\n${value}`;
                    }
                    errorString += `\n\nPlease, fix and try again.`;
                    alert(errorString);
                    this.isSubmitting = false;
                });
        },
        handlePrev() {
            const _currentStep = this.modelValue - 1;
            this.$emit('update:modelValue', _currentStep);
        },
        updateFormData() {
            this.existingFiles = this.existingTrip.files;
            this.form = this.existingTrip;

            let files = {};
            this.existingTrip.files.forEach(file => {
                if (Array.isArray(files[file.type])) {
                    files[file.type].push(file.id);
                } else {
                    files[file.type] = [file.id];
                }
            });
            this.form.files = files;

            if (this.tripType == 'transit') {
                delete(this.form.destination_address);
                delete(this.form.destination_latitude);
                delete(this.form.destination_longitude);
            } else if (this.tripType == 'external') {
                delete(this.form.to_country_id);
                delete(this.form.border_to_id);
            }

            this.form.date_of_arrival = this.formatDate(this.form.date_of_arrival);
            this.form.cargo_infos.forEach((cargoInfo) => {
                cargoInfo.clearance_date = this.formatDate(cargoInfo.clearance_date);
            });
        },
        getTypedFilesTitle(type) {
            switch (type) {
                case 'manifest':
                    return this.$root.__('json.Manifest');
                case 'cert_invoice':
                    return this.$root.__('json.Certificate of Origin and Invoice');
                case 'truck_licence':
                    return this.$root.__('json.Truck licence');
                case 'packing_list':
                    return this.$root.__('json.Packing list');
                case 'bill':
                    return this.$root.__('json.Bill Of Lading Or Airway Bill');
                case 'delivery_order':
                    return this.$root.__('json.Delivery order');
                case 'other':
                    return this.$root.__('json.Other files');
            }
        },
        formatDate(dateString) {
            if (!dateString) {
                return null;
            }
            let date = new Date(dateString);
            let year = date.toLocaleString("default", { year: "numeric" });
            let month = date.toLocaleString("default", { month: "2-digit" });
            let day = date.toLocaleString("default", { day: "2-digit" });

            return year + "-" + month + "-" + day;
        },
    },
}
</script>
